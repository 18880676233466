/* Base Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body and HTML */
html,
body {
  height: 100%; /* Ensures the app takes the full height */
}

body {
  font-family: "Arial", sans-serif; /* Replace with your preferred font */
  display: flex;
  flex-direction: column;
}

/* App Container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the app fills the viewport height */
}

/* Navbar */
nav {
  flex-shrink: 0; /* Prevents Navbar from shrinking */
}

/* Main Content */
.content-container {
  flex: 1; /* Expands to take up all available space */
  padding: 16px; /* Optional: Add some spacing around the content */
}

/* Footer */
footer {
  flex-shrink: 0; /* Prevents Footer from shrinking */
  background-color: #222; /* Footer styling */
  color: white;
  text-align: center;
  padding: 16px;
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Move up */
  }
  100% {
    transform: translateY(0); /* Move back down */
  }
}

.up-down-animation {
  animation: upDown 4s ease-in-out infinite; /* 4s duration, infinite loop */
}
