@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Ranade";
  src: url("../src/fonts/Ranade-Variable.ttf") format("truetype");
}
* {
  scroll-behavior: smooth !important;
}

body {
  font-family: "Ranade" !important;
  scroll-behavior: smooth !important;
}

.layoutUpifyme {
  max-width: 1050px;
  width: 100%;
  margin: auto;
}
.upifymeBlog > h1 {
  font-size: 2em;
  margin: 0.67em 0;
  font-weight: bold;
}

.upifymeBlog > h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
  font-weight: bold;
}

.upifymeBlog > h3 {
  font-size: 1.17em;
  margin: 1em 0;
  font-weight: bold;
}

.upifymeBlog > h4 {
  font-size: 1em;
  margin: 1.33em 0;
  font-weight: bold;
}

.upifymeBlog > h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
  font-weight: bold;
}

.upifymeBlog > h6 {
  font-size: 0.67em;
  margin: 2.33em 0;
  font-weight: bold;
}

.upifymeBlog > p {
  margin: 1em 0;
}

.upifymeBlog > b {
  font-weight: bold;
}

.upifymeBlog > i {
  font-style: italic;
}

.upifymeBlog > u {
  text-decoration: underline;
}
